<template>
<div class="counter-info">
  <div
      v-for="(item, index) of counterInfoList"
      :key="index"
      class="counter-info__cell"
  >
    <div class="counter-info__title-block">
      <span
          v-if="extractNumberAndText(item.title).textBefore"
          class="counter-info__title counter-info__title--mini-before"
      >
        {{ extractNumberAndText(item.title).textBefore }}
      </span>
      <span class="counter-info__title">
        {{ extractNumberAndText(item.title).num }}
      </span>
      <span
          v-if="extractNumberAndText(item.title).textAfter"
          class="counter-info__title counter-info__title--mini-after"
      >
        {{ extractNumberAndText(item.title).textAfter }}
      </span>
    </div>
    <p class="counter-info__text">
      {{ item.text }}
    </p>
  </div>
</div>
</template>

<script>
export default {
  name: 'CounterInfo',
  props: {
    counterInfoList: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  methods: {
    extractNumberAndText(str) {
      const num = str.match(/\d+/g)[0];
      const textBefore = str.match(/^[^\d]+/g);
      const textAfter = str.match(/[^\d]+$/g);
      return { num, textBefore: textBefore ? textBefore[0].trim() : null, textAfter: textAfter ? textAfter[0].trim() : null };
    },
  },
}
</script>

<style lang="less" scoped>
.counter-info {
  margin-right: 40px;
  max-width: 400px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;

  &__title-block {
    display: flex;
    flex-wrap: wrap;
  }

  &__title {
    margin: 0;
    font-family: @font-montserrat;
    color: @dark-300;
    font-size: @font-size-40;
    line-height: @line-height-46;
    font-weight: @font-weight-800;

    &--mini-before {
      font-size: @font-size-20;
      line-height: @line-height-46;
      margin-right: 8px;
    }

    &--mini-after {
      align-self: center;
      font-size: @font-size-20;
      line-height: @line-height-26;
      margin-left: 8px;
    }
  }

  &__text {
    margin: 0;
    text-transform: uppercase;
    font-family: @font-fira-sans;
    color: @dark-200;
    font-size: @font-size-13;
    line-height: @line-height-15;
    font-weight: @font-weight-500;
  }
}

@media screen and (max-width: 530px) {
  .counter-info {
    margin: 20px auto 0;
    gap: 16px;
    order: 2;
  }
}

@media screen and (max-width: 332px) {
  .counter-info {
    grid-template-columns: 126px 126px;

    &__text {
      font-size: @font-size-11;
    }
  }
}
</style>
