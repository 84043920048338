<template>
<div class="vacancies-list">
  <div
      v-for="item of vacancies"
      :key="item.name"
      class="vacancies-list__item"
  >
    <h2 class="vacancies-list__title">
      {{ item.title }}
    </h2>
    <div
        v-for="(description, index) in item.descriptions"
        :key="index"
        class="vacancies-list__description"
    >
      <h3 class="vacancies-list__subtitle">
        {{ description.descriptionTitle }}
      </h3>
      <div v-if="description.descriptionJobRequirements">
        <div
            v-for="(jobRequirement, jobRequirementIndex) in description.descriptionJobRequirements"
            :key="jobRequirementIndex"
            class="vacancies-list__description"
        >
          <h4 class="vacancies-list__subtitle vacancies-list__subtitle--red">
            {{ jobRequirement.descriptionSubtitle }}
          </h4>
          <p
              v-for="(textItem, textItemIndex) in jobRequirement.descriptionText"
              :key="textItemIndex"
              class="vacancies-list__text"
          >
            {{ textItem }}
          </p>
        </div>
      </div>
      <p
          v-for="(textItem, textIndex) in description.descriptionText"
          :key="textIndex"
          class="vacancies-list__text"
      >
        {{ textItem }}
      </p>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'VacanciesList',
  props: {
    vacancies: {
      type: Array,
      required: true,
      default: () => []
    }
  }
}
</script>

<style lang="less" scoped>
.vacancies-list {
  margin-top: 60px;
  padding: 0 24px;

  &__item {
    margin-bottom: 60px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__title {
    margin: 0 0 16px;
    color: @dark-300;
    font-family: @font-fira-sans;
    font-size: @font-size-38;
    line-height: @line-height-44;
    font-weight: @font-weight-500;
  }

  &__description {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__subtitle {
    margin: 0 0 12px;
    padding-left: 24px;
    color: @dark-200;
    font-family: @font-fira-sans;
    font-size: @font-size-26;
    line-height: @line-height-32;
    font-weight: @font-weight-400;

    &--red {
      padding-left: 48px;
      color: @darker;
      font-family: @font-fira-sans;
      font-size: @font-size-18;
      line-height: @line-height-24;
      font-weight: @font-weight-500;
    }
  }

  &__text {
    margin: 0;
    padding-left: 48px;
    color: @dark-300;
    font-family: @font-fira-sans;
    font-size: @font-size-16;
    line-height: @line-height-22;
    font-weight: @font-weight-400;

    &::before {
      content: "•";
      color: @main;
      margin-right: 8px;
    }
  }
}

@media screen and (max-width: 530px) {
  .vacancies-list {
    padding: 0 16px;

    &__subtitle {
      padding-left: 16px;

      &--red {
        padding-left: 32px;
      }
    }

    &__text {
      padding-left: 32px;
    }
  }
}
</style>
