<template>
<div class="list">
  <ul class="list__list">
    <li
        v-for="(item, index) in list"
        :key="index"
        class="list__item"
    >
      {{ item }}
    </li>
  </ul>
</div>
</template>

<script>
export default {
  name: 'ListComponent',
  props: {
    list: {
      type: Array,
      required: true,
      default: () => []
    }
  }
}
</script>

<style lang="less" scoped>
.list {
  margin: 0 auto;
  padding: 0 24px;

  &--no-padding {
    padding: 0;
  }

  &__list {
    margin: 0 auto;
    padding: 0;
    max-width: 800px;
    list-style: none;
  }

  &__item {
    color: @dark-300;
    font-family: @font-fira-sans;
    font-size: @font-size-26;
    line-height: @line-height-32;
    font-weight: @font-weight-400;

    &::before {
      content: "•";
      color: @main;
      margin-right: 8px;
    }
  }
}

@media screen and (max-width: 847px) {
  .list {
    padding: 0 16px;

    &__list {
      max-width: 600px;
    }
  }
}

@media screen and (max-width: 647px) {
  .list__list {
    max-width: 400px;
  }

  .list__item {
    font-size: @font-size-18;
    line-height: @line-height-24;
    font-weight: @font-weight-400;
  }
}
</style>
