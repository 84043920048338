<template>
<div class="footer">
  <div class="footer__information">
    <div class="footer__information-cell">
      <h2 class="footer__information-title">Юридический адрес</h2>
      <p class="footer__information-text">107113, г. Москва, ул. Маленковская, д. 32, стр. 3, эт. 1, пом. I, комната 43</p>
    </div>
    <div class="footer__information-cell">
      <h2 class="footer__information-title">Информация об аккредитации</h2>
      <p class="footer__information-text">Информация об аккредитации ИТ-компании в Российской Федерации: 10302, Решение Минцифры об аккредитации от 05.06.2019 №258</p>
    </div>
    <div class="footer__information-cell">
      <h2 class="footer__information-title">Реквизиты</h2>
      <ul class="footer__information-list">
        <li class="footer__information-text">ИНН: 9718130404</li>
        <li class="footer__information-text">КПП: 771801001</li>
        <li class="footer__information-text">ОГРН: 1197746139414</li>
        <li class="footer__information-text">ОКТМО: 45315000</li>
      </ul>
    </div>
    <div class="footer__information-cell">
      <h2 class="footer__information-title">ОКВЭД</h2>
      <p class="footer__information-text">62.01 Разработка компьютерного программного обеспечения</p>
    </div>
  </div>
  <div class="footer__contacts">
    <div class="footer__contacts-item">
      <a class="footer__contacts-link" href="tel:+74991302007">+7 499 130 20 07</a>
    </div>
    <div class="footer__contacts-item">
      <a class="footer__contacts-link" href="mailto:info@komus-tech.ru">info@komus-tech.ru</a>
      <p class="footer__contacts-text">По всем вопросам</p>
    </div>
  </div>
  <p class="footer__copyright">&copy; 2023 ООО «КОМУС-ТЕХ»</p>
</div>
</template>

<script>
export default {
  name: 'FooterComponent'
}
</script>

<style scoped lang="less">
.footer {
  margin: 0 auto;
  padding: 100px 24px 0 24px;

  &__information {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 60px 80px;
    font-family: @font-fira-sans;
  }

  &__information-title {
    margin: 0 0 16px;
    color: @dark-100;
    font-size: @font-size-16;
    line-height: @line-height-22;
    font-weight: @font-weight-500;
  }

  &__information-text {
    margin: 0;
    color: @dark-300;
    font-size: @font-size-16;
    line-height: @line-height-22;
    font-weight: @font-weight-400;

  }

  &__information-list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__contacts {
    margin-top: 40px;
    display: flex;
  }

  &__contacts-item {
    margin-right: 100px;
  }

  &__contacts-link {
    text-decoration: none;
    white-space: nowrap;
    color: @dark-300;
    font-family: @font-montserrat;
    font-size: @font-size-40;
    line-height: @line-height-46;
    font-weight: @font-weight-800;
  }

  &__contacts-text {
    margin: 8px 0 0;
    text-transform: uppercase;
    color: @dark-300;
    font-family: @font-fira-sans;
    font-size: @font-size-11;
    line-height: @line-height-12;
    font-weight: @font-weight-500;
  }

  &__copyright {
    margin: 100px 0 12px;
    color: @dark-300;
    font-family: @font-fira-sans;
    font-size: @font-size-13;
    line-height: @line-height-15;
    font-weight: @font-weight-500;
  }
}

@media screen and (max-width: 1023px) {
  .footer__information {
    gap: 40px 60px;
  }

  .footer__contacts {
    display: block;
  }

  .footer__contacts-item {
    margin-right: 0;
    margin-bottom: 40px;
  }

  .footer__copyright {
    margin: 0 0 12px;
  }
}

@media screen and (max-width: 767px) {
  .footer__contacts-link {
    white-space: normal;
    font-size: @font-size-32;
    line-height: @line-height-38;
    font-weight: @font-weight-800;
  }
}

@media screen and (max-width: 425px) {
  .footer {
    padding: 60px 16px 0 16px;

    &__information {
      display: block;
    }

    &__information-cell {
      margin-bottom: 24px;
    }

    &__information-title {
      margin: 0 0 4px;
    }
  }
}
</style>
