<template>
<div class="info-list">
  <h2 class="info-list__title">
    {{ infoList.title }}
  </h2>
  <ul class="info-list__list">
    <li
        v-for="(item, index) in infoList.elements"
        :key="index"
        class="info-list__list-item"
        :class="{ 'info-list__list-item--solo': !item.title }"
    >
      <h3
          v-if="item.title"
          class="info-list__subtitle"
      >
        {{ item.title }}
      </h3>
      <p class="info-list__text">
        {{ item.text }}
      </p>
    </li>
  </ul>
</div>
</template>

<script>
export default {
  name: 'InfoListRed',
  props: {
    infoList: {
      type: Object,
      required: true,
      default: () => {}
    }
  }
}
</script>

<style lang="less" scoped>
.info-list {
  margin-top: 40px;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: @light-100;
  background-color: @darker;
  border-radius: 25px;

  &__title {
    max-width: 800px;
    margin: 40px 0;
    font-family: @font-montserrat;
    font-size: 60px;
    line-height: 62px;
    font-weight: @font-weight-800;
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
  }

  &__list-item {
    padding: 20px 0 100px;
    width: 800px;
    border-top: 1px solid;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &--solo {
      justify-content: flex-end;
    }
  }

  &__subtitle {
    margin: 0;
    font-family: @font-montserrat;
    font-size: @font-size-20;
    line-height: @line-height-26;
    font-weight: @font-weight-800;
  }

  &__text {
    margin: 0;
    width: 400px;
    font-family: @font-fira-sans;
    font-size: @font-size-18;
    line-height: @line-height-24;
    font-weight: @font-weight-500;
  }
}

@media screen and (max-width: 1392px) {
  .info-list {
    border-radius: 0;
  }
}

@media screen and (max-width: 847px) {
  .info-list__title {
    max-width: 600px;
    font-size: @font-size-40;
    line-height: @line-height-46;
  }

  .info-list__list-item {
    width: 600px;
  }

  .info-list__text {
    width: 400px;
  }
}

@media screen and (max-width: 647px) {
  .info-list__title {
    max-width: 400px;
    font-size: @font-size-32;
    line-height: @line-height-38;
  }

  .info-list__list-item {
    width: 400px;
  }

  .info-list__text {
    width: 200px;
  }
}

@media screen and (max-width: 447px) {
  .info-list {
    padding: 0 16px;
    align-items: center;

    &__title {
      text-align: right;
      font-size: @font-size-20;
      line-height: @line-height-26;
    }

    &__list {
      align-items: flex-end;
    }

    &__list-item {
      width: 100%;
      padding: 20px 0 60px;
      flex-direction: column;
      align-items: flex-end;
    }

    &__subtitle {
      margin-bottom: 20px;
    }

    &__text {
      width: 100%;
      text-align: right;
    }
  }
}
</style>
