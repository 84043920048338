<template>
<div class="image-container">
  <img
      :src="resolvedImagePath"
      alt="Картинка"
      class="image-container__image"
  />
</div>
</template>

<script>
export default {
  name: 'ImageComponent',
  props: {
    image: {
      type: String,
      required: true,
      default: 'Здесь должна быть стандартная картинка из папки assets'
    }
  },
  computed: {
    resolvedImagePath() {
      return require(`@/assets/${this.image}`)
    }
  }
}
</script>

<style lang="less" scoped>
.image-container {
  margin: 40px auto 0;

  &__image {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 25px;
  }
}

@media screen and (max-width: 1392px) {
  .image-container {
    padding: 0;

    &__image {
      border-radius: 0;
    }
  }
}
</style>
