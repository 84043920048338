import { createRouter, createWebHistory } from 'vue-router'
import Main from "@/pages/Main";
import Vacancies from "@/pages/Vacancies";

const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: '/', component: Main },
        { path: '/vacancies', component: Vacancies }
    ]
})

export default router
