<template>
<div class="title">
  <h1 class="title__text">
    {{ titleText }}
  </h1>
</div>
</template>

<script>
export default {
  name: 'TitleComponent',
  props: {
    titleText: {
      type: String,
      default: 'Заголовок'
    }
  }
}
</script>

<style lang="less" scoped>
.title {
  padding: 160px 24px 20px;

  &__text {
    margin: 0;
    text-align: right;
    text-transform: uppercase;
    color: @dark-300;
    font-family: @font-fira-sans;
    font-size: 136px;
    font-weight: @font-weight-800;
  }
}

@media screen and (max-width: 1023px) {
  .title {
    padding: 140px 24px 20px;

    &__text {
      font-size: 112px;
    }
  }
}

@media screen and (max-width: 767px) {
  .title {
    padding: 100px 24px 20px;

    &__text {
      font-size: 72px;
    }
  }
}

@media screen and (max-width: 530px) {
  .title {
    padding: 60px 24px 0px;

    &__text {
      font-size: 56px;
    }
  }
}

@media screen and (max-width: 425px) {
  .title {
    padding: 40px 16px 20px;

    &__text {
      font-size: 40px;
    }
  }
}
</style>
