<template>
  <div class="team-list">
    <div
        v-for="(item, index) in teamList"
        :key="index"
        class="team-list__item"
    >
      <img
          :src="resolvedImagePath(item.image)"
          alt="Фотография"
          class="team-list__image"
      >
      <div class="team-list__description">
        <h3 class="team-list__title">
          {{ item.name }}
        </h3>
        <ul class="team-list__position-list">
          <li
              v-for="(position, index) in item.position"
              :key="index"
              class="team-list__position-list-item"
          >
            {{ position }}
          </li>
        </ul>
        <ul class="team-list__responsibilities-list">
          <li
              v-for="(responsibility, index) in item.responsibilities"
              :key="index"
              class="team-list__responsibilities-list-item"
          >
            {{ responsibility }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TeamList',
  props: {
    teamList: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  methods: {
    resolvedImagePath(image) {
      return require(`@/assets/${image}`)
    }
  }
}
</script>

<style lang="less" scoped>
.team-list {
  margin: 0 auto;
  padding: 0 24px;

  &__item {
    margin-bottom: 40px;
    display: flex;
  }

  &__image {
    margin-right: 80px;
    min-width: 150px;
    max-width: 150px;
    height: 200px;
    object-fit: cover;
    border-radius: 5px;
    filter: grayscale(100%);
  }

  &__description {

  }

  &__title {
    margin: 0 0 20px;
    color: @dark-300;
    font-family: @font-fira-sans;
    font-size: @font-size-26;
    line-height: @line-height-32;
    font-weight: @font-weight-400;
  }

  &__position-list {
    margin: 0 0 20px;
    padding: 0;
    list-style: none;
  }

  &__position-list-item {
    color: @darker;
    font-family: @font-fira-sans;
    font-size: @font-size-18;
    line-height: @line-height-24;
    font-weight: @font-weight-500;
  }

  &__responsibilities-list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__responsibilities-list-item {
    color: @dark-300;
    font-family: @font-fira-sans;
    font-size: @font-size-16;
    line-height: @line-height-22;
    font-weight: @font-weight-400;

    &::before {
      content: "•";
      color: @main;
      margin-right: 8px;
    }
  }
}

@media screen and (max-width: 530px) {
  .team-list {
    padding: 0 16px;

    &__item {
      margin-bottom: 40px;
      display: flex;
      flex-direction: column;
    }

    &__image {
      margin: 0;
      align-self: center;
    }

    &__description {
      margin-top: 20px;
    }

    &__title {
      text-align: center;
    }
  }
}
</style>
