<template>
<div class="map">
  <iframe
      class="map__frame"
      src="https://yandex.ru/map-widget/v1/?um=constructor%3Ae4e42fb018bb3051e1041582c69ebdceb5c237e1521c9ea25c7e2fbdd1fa41cb&amp;source=constructor"
      frameborder="0"
  />
</div>
</template>

<script>
export default {
  name: 'YandexMap'
}
</script>

<style lang="less" scoped>
.map {
  margin-top: 120px;

  &__frame {
    width: 100%;
    height: 400px;
    border: 1px solid @main;
    border-radius: 15px;
  }
}

@media screen and (max-width: 1392px) {
  .map__frame {
    border-radius: 0;
    border: 1px solid @main;
    border-left: 0;
    border-right: 0;
  }
}

@media screen and (max-width: 530px) {
  .map__frame {
    height: 360px;
  }
}
</style>
