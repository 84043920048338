<template>
  <div class="subtitle">
    <h2 class="subtitle__text">
      {{ subtitleText }}
    </h2>
  </div>
</template>

<script>
export default {
  name: 'SubtitleComponent',
  props: {
    subtitleText: {
      type: String,
      required: true,
      default: 'Подзаголовок'
    }
  }
}
</script>

<style lang="less" scoped>
.subtitle {
  margin: 80px auto 40px;
  padding: 0 24px;

  &__text {
    margin: 0;
    text-align: center;
    color: @dark-300;
    font-family: @font-montserrat;
    font-size: 66px;
    line-height: 72px;
    font-weight: @font-weight-700;
  }
}

@media screen and (max-width: 647px) {
  .subtitle {
    padding: 0 16px;

    &__text {
      font-size: 56px;
      line-height: 62px;
    }
  }
}

@media screen and (max-width: 446px) {
  .subtitle__text {
    font-size: 46px;
    line-height: 52px;
  }
}
</style>
