<template>
<div class="text-container">
  <p class="text-container__text">
    {{ text }}
  </p>
</div>
</template>

<script>
export default {
  name: 'TextComponent',
  props: {
    text: {
      type: String,
      required: true,
      default: 'Текст'
    }
  }
}
</script>

<style lang="less" scoped>
.text-container {
  max-width: 440px;

  &__text {
    margin: 0;
    font-family: @font-fira-sans;
    color: @dark-300;
    font-size: @font-size-26;
    line-height: @line-height-32;
    font-weight: @font-weight-400;
  }
}

@media screen and (max-width: 1023px) {
  .text-container {
    max-width: 240px;
  }
}

@media screen and (max-width: 530px) {
  .text-container {
    width: 100%;
    margin-bottom: 40px;
    order: 1;
    align-self: flex-end;

    &__text {
      text-align: right;
      font-size: @font-size-16;
      line-height: @line-height-22;
    }
  }
}
</style>
