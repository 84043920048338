<template>
<div>
  <title-component
      :title-text="'Комус-Технологии'"
  />
  <div class="info-container">
    <counter-info
        :counter-info-list="this.data.firstCounterStatistics"
    />
    <text-component
        :text="data.mainSubtitleText"
    />
  </div>
  <info-list-red
      :info-list="data.firstInfoListRed"
  />
  <subtitle-component
      :subtitle-text="'Принципы'"
  />
  <list-component
      :list="data.firstInfoList"
  />
  <image-component
      :image="'first-image.jpg'"
  />
  <subtitle-component
      :subtitle-text="'Виды задач'"
  />
  <div class="info-container">
    <counter-info
        :counter-info-list="data.secondCounterStatistics"
    />
    <list-component
        :list="data.secondInfoList"
        class="info-container__list"
    />
  </div>
  <info-list-red
      :info-list="data.secondInfoListRed"
  />
  <image-component
      :image="'second-image.jpg'"
  />
  <subtitle-component
      :subtitle-text="'Команда'"
  />
  <team-list
      :team-list="data.teamList"
  />
  <subtitle-component
      :subtitle-text="'Этапы'"
  />
  <list-component
      :list="data.thirdInfoList"
  />
  <yandex-map />
</div>
</template>

<script>
import data from './main.json'
import TitleComponent from "@/components/Title";
import InfoListRed from "@/components/InfoListRed";
import SubtitleComponent from "@/components/Subtitle";
import ListComponent from "@/components/List";
import CounterInfo from "@/components/CounterInfo";
import TextComponent from "@/components/Text";
import ImageComponent from "@/components/Image";
import TeamList from "@/components/TeamList";
import YandexMap from "@/components/YandexMap";

export default {
  name: 'MainPage',
  components: {
    YandexMap,
    TeamList,
    ImageComponent,
    TextComponent,
    CounterInfo,
    ListComponent,
    SubtitleComponent,
    InfoListRed,
    TitleComponent
  },
  data() {
    return {
      data
    }
  }
}
</script>

<style lang="less" scoped>
.info-container {
  margin: 40px auto 0;
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  &__list {
    margin: 0;
    padding: 0;
  }
}

@media screen and (max-width: 530px) {
  .info-container {
    padding: 0 16px;
    flex-direction: column;
  }
}
</style>
