<template>
  <div>
    <TitleComponent title-text="Вакансии" />
    <vacancies-list :vacancies="data.vacancies" />
  </div>
</template>

<script>
import data from './vacancies.json'
import TitleComponent from "@/components/Title";
import VacanciesList from "@/components/VacanciesList";

export default {
  name: "VacanciesPage",
  components: {
    VacanciesList,
    TitleComponent
  },
  data() {
    return {
      data
    }
  }
}
</script>
