<template>
<div class="header">
  <router-link to="/">
    <img class="header__logo" src="../assets/komus.svg" alt="Logo">
  </router-link>
  <nav class="header__navigation">
    <router-link to="/vacancies" class="header__navigation-link">Вакансии</router-link>
  </nav>
</div>
</template>

<script>
export default {
  name: 'HeaderComponent'
}
</script>

<style lang="less" scoped>
.header {
  margin: 0 auto;
  padding: 28px 48px 0 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__logo {
    width: 180px;
    height: 60px;
  }

  &__navigation-link {
    text-decoration: none;
    color: @dark-300;
    font-family: @font-fira-sans;
    font-size: @font-size-18;
    line-height: @line-height-24;
    font-weight: @font-weight-500;
    transition: color 0.2s linear;

    &:hover {
      color: @main;
    }
  }
}

@media screen and (max-width: 1023px) {
  .header {
    padding: 28px 24px 0 24px;

    &__logo {
      width: 140px;
      height: 60px;
    }
  }
}

@media screen and (max-width: 425px) {
  .header {
    padding: 16px 16px 0 16px;
  }
}


</style>
